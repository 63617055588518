<template>
    <div class="detail-wrap" style="padding-top: 30px;">
        <el-breadcrumb separator="/" class="path">
            <el-breadcrumb-item :to="{ path: '/' }" class="path-home"> 首页 </el-breadcrumb-item>
            <!-- <el-breadcrumb-item :to="{ path: '/cms/help' }">帮助列表</el-breadcrumb-item> -->
            <el-breadcrumb-item class="path-help" ><span style="color: #ed6030 !important;">新手指南</span></el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div class="help-detail" v-loading="loading">
            <div class="title">{{ detail.title }}</div>
            <div class="info">
                <div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
            </div>
            <div class="content" v-html="detail.content"></div>
        </div> -->


        <div class="help_vip"><img src="../../../assets/images/help_vip.jpg" alt=""></div>

        <div class="help_content">
            <!-- 关于会员 -->
            <div class="help_num1">
                <div class="help_vipcontent1">
                    <div class="help_title1">关于会员：</div>
                    <div class="help_list1">
                        <ul>
                            <li>点击页顶的注册进入注册页面，填写个人信息，点击“同意协议并注册”，完成注册。
                              把会员名（用户名）发送给您的客户经理审核，审核通过后即可登陆下单。客户经理会根据您的订单月均采购量进行会员等级调整。</li>
                            <li><strong>温馨提示:</strong></li>
                          <li>请务必填写正确有效的电话号码，否则当您忘记注册密码时，将无法成功找回。
                            为了您的账户安全，在设置密码时，请参考以下建议：</li>

                          <li>A. 密码由6-16位字符组成（字母区分大小写）。</li>
                          <li>B.请使用数字、字母或符号的组合。</li>
                          <li>C.忘记密码：登录页面点击忘记密码→输入手机号和验证码→输入新密码。</li>
                          <li>D.更改密码：会员中心→会员信息管理→密码管理→请仔细确认输入原密码和新密码→确定修改。</li>
                          <li>E.修改会员信息：会员中心→会员信息管理→会员信息→请仔细确认输入需修改资料→提交保存。</li>
                        </ul>
                    </div>
                </div>
            </div>


            <!-- 关于订单 -->
            <div class="help_num2">
                <div class="help_vipcontent2">
                    <div class="help_title1">关于订单：</div>
                    <div class="help_list1">
                        <ul>
                            <li>
                                <b>A.下订单：</b>
                              选择您要的产品—>输入购买数量—>加入购物车—>提交订单—>选择收货地址—>选择配送方式和备注其他要求—>提交订单—>联系客户经理审核订单，—>付款—>客户经理安排仓库配货，打包，发货。
                            </li>
                            <li><b>B.下预订单：</b> 选择您要的产品—>输入购买数量—>加入购物车—>提交预订单—>联系客户经理。</li>
                            <li><b>C.查看订单和预订单：</b> (页顶)我的订单→查看订单列表/预订单列表。</li>
                            <li><b>D.修改订单和预订单：</b> 订单列表→查看（须在订单状态为新订单未确认状态）→勾选左边的复选框→进行拆分订单。注意拆分后的订单金额不能低于1000元。</li>
                            <li><b>E.取消订单：</b> 如您需取消订单，请在订单状态为“新订单未确认”时，点击取消。</li>
                        </ul>
                    </div>
                </div>
                <!-- <div><img src="../../../assets/images/help_vip2.png" alt=""></div> -->
            </div>


            <!-- 关于付款 -->
            <div class="help_num3">
                <div class="help_vipcontent3">
                    <div class="help_title1">关于付款：</div>
                    <div class="help_list1">
                        <ul>
                            <li>现金结算客户，需要先付款再发货。可通过支付宝、银行汇款或邮局汇款等方式付款。汇款后客户可以通过QQ或打电话等方式联系您的客户经理，通知我们您的货款已汇出，并提供订单号，以便我们及时查帐，及时发货。</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="help_num3">
              <div class="help_vipcontent3">
                <div class="help_title1">关于配送：</div>
                <div class="help_list1">
                  <ul>
                    <li>
                      <b>A.配送方式：</b>
                      以订单金额、收货地址决定快递或物流或其他方式，我们将根据您所在的地址，货物体积，货物重量选择最合适的快递方式或物流方式或直送。
                    </li>
                    <li><b>B.配送费用：</b> 快递默认中通快递，按实际费用收取，物流默认运费到付，如有特殊情况请提前与您的客户经理联系。</li>
                    <li><b>C.配送时间：</b> 因收货地址的不同，疫情、路况均无法估计，如超过一周未收到货物请第一时间联系您的客户经理进行处理，偏远地区以10天为准。</li>
                  </ul>
                </div>
              </div>
              <!-- <div><img src="../../../assets/images/help_vip3.png" alt=""></div> -->
            </div>

            <!-- 关于售后 -->
            <div class="help_num4">
                <div class="help_vipcontent4">
                    <div class="help_title1">关于售后：</div>
                    <div class="help_list1">
                        <ul>
                          <li>A.如您收到产品与卖出的产品产生质量问题，请在收到货物7天内，联系您的客户经理。</li>
                          <li>B.如您收到产品有少货或破损现象，请在未确认签收的情况下联系您的客户经理进行处理。</li>
                          <li>C.如您收到的产品不会安装，可参考图纸，再联系您的客户经理进行处理。</li>
                          <li>D.如您收到的产品缺少配件，请联系您的客户经理进行处理。</li>
                        </ul>
                    </div>
                </div>
                <!-- <div> <img src="../../../assets/images/help_vip4.png" alt=""></div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { helpDetail } from "@/api/cms/help"
    export default {
        name: "help_detail",
        components: {},
        data: () => {
            return {
                detail: [],
                loading: true
            }
        },
        created() {
            this.id = this.$route.path.replace("/cms/help-", "")
            this.getDetail()
        },
        watch: {
            $route(curr) {
                this.id = curr.params.pathMatch
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                helpDetail({
                    id: 4
                })
                    .then((res) => {
                        if (res.code == 0) {
                            if (res.data) {
                                this.loading = false
                                this.detail = res.data
                            } else {
                                this.$router.push({ path: "/cms/help" })
                            }
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .help-detail {
        background-color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        margin: 10px 0;

        .title {
            text-align: center;
            font-size: 18px;
            margin: 10px 0;
        }

        .info {
            margin: 0 43px;
            border-bottom: 1px dotted #e9e9e9;

            .time {
                text-align: center;
                color: #838383;
                margin-bottom: 17px;
            }
        }

        .content {
            padding-top: 10px;
            margin: 0 65px;
        }
    }

    .path {
        // padding: 15px 0;
    }

    .help_vip {
        margin-top: 30px;
    }

    .help_num1,
    .help_num2,
    .help_num3 {
        border-bottom: 2px solid #ededed;
        font-family: "微软雅黑";
        margin: 10px;

    }

    .help_title1,
    .help_title2,
    .help_title3,
    .help_title4 {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    *li {
        margin-bottom: 30px;
        font-size: 15px;

    }
</style>
